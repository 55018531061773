import React, { useState } from 'react';

import StoreData from '../../data/store';

import Row from '../Container/row';
import Col from '../Container/column';

import loadComponents from '../Loadable';

const Form = loadComponents('form');

export default function Download({ order }) {
  const [download, setDownload] = useState(false);

  const handleSubmit = ({ data, error }) => {
    StoreData.downloadConfirm(order, data.email)
      .then(res => {
        if (res.error) {
          error(res);
        }
        setDownload(res);
      })
      .catch(err => error(err));
  };

  const handleDownload = ({ data, error, success }) => {
    if (data.confirmation) {
      StoreData.downloadFile(download.download)
        .then(res => {
          const url = window.URL.createObjectURL(
            // eslint-disable-next-line no-undef
            new Blob([res], { type: 'application/zip' }),
          );

          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `VISA-DOCUMENT-${order}.zip`);
          document.body.appendChild(link);
          link.click();

          success(
            `You have successfully downloaded your visa documentation. Thanks for choosing Visa Applications.`,
          );
        })
        .catch(err => error(err))
        .finally(() => StoreData.updateOrder(order, false, true));
    }
  };

  const downloadFields = [
    {
      title: '',
      fields: [
        {
          type: 'input',
          inputType: 'checkbox',
          width: '100%',
          name: 'confirmation',
          label: `I ${download.first_name} ${download.last_name} (${download.email}) confirm that I have received my travel documentation.`,
          required: true,
        },
        {
          type: 'submit',
          width: '100%',
          buttonLabel: 'Download Document',
        },
      ],
    },
  ];

  return (
    <Row>
      <Col lg={{ width: `650px` }}>
        {!download && (
          <Form name="download" fields={emailFields} action={handleSubmit} />
        )}
        {!!download && (
          <>
            <p>
              Below is your travel document which is secured with a password.
              <br />
              This is your date of birth formatted as:
            </p>
            <h2>DDMMYYYY</h2>
            <p>
              <strong>Example:</strong> If your date of birth is 1st January
              1999 (01/01/1999)
              <br />
              Your password would be: <strong>01011999</strong>
            </p>
            <hr />
            <Form
              name="confirmation"
              fields={downloadFields}
              action={handleDownload}
            />
          </>
        )}
      </Col>
    </Row>
  );
}

const emailFields = [
  {
    title: '',
    fields: [
      {
        type: 'input',
        inputType: 'email',
        width: '100%',
        name: 'email',
        label: 'Please confirm your email address',
        placeholder: 'email@example.com',
        required: true,
      },
      {
        type: 'submit',
        width: '100%',
        buttonLabel: 'Confirm Email',
      },
    ],
  },
];
